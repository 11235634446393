<template>
  <div>
     <Form
      v-if="form_id"
      id="galapagos"
      :form_id="form_id"
      :server="server"
      v-on:results="getResult"
      v-on:form_submit_loading="loading"
      @on_error="viewError"
      :hideError="hiderror"
      :relay="datas"
    />
  </div>
</template>

<script>

export default {
  name: "GalapagosView",
  data() {
    return {
      datas : {},
      form_id: '6644f655bb456dd5f8e08602',
      hiderror: true,
      server: "https://api.lucien.ai",
    };
  },
    mounted() {
    this.datas.first_name = this.$route.query.first_name;
    this.datas.last_name = this.$route.query.last_name;
    this.datas.siret = this.$route.query.siret;
    this.datas.iban = this.$route.query.iban;
    if(this.$route.query.email)this.datas.email = this.$route.query.email;
  },

  methods: {
    getResult(result) {
      if(result) {
       // this.$router.push("/valid");
       const message = {
          type: "form-submission",
          payload: result,
        };

        window.parent.postMessage(message, "*");
      }
    },
    loading() {
      this.openModal();
    },
    viewError() {
      this.openError();
    },
    openModal() {
      this.$Simplert.open({
        title: "Validation en cours",
        message: "merci de bien vouloir patienter...",
        hideAllButton: true,
      });
    },
  },
};
</script>
