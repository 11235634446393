import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import CalypsoView from "../views/CalypsoView.vue";
import GalapagosView from "../views/GalapagosView.vue";
import ValidView from "../views/ValidView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/calypso",
    name: "calypso",
    component: CalypsoView,
  },
  {
    path: "/status/:_op/:_id/:_secu",
    name: "Status",
    component: () => import("../views/StatusView.vue"),
  },
  {
    path: "/valid",
    name: "validview",
    component: ValidView,
  },
  {
    path: "/galapagos",
    name: "galapagos",
    component: GalapagosView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
